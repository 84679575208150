export const ENV = 'coesco';
export const PAGE_TITLE = "Información Factura Electrónica Cliente Eventual EDS Primax operadas por Coesco";
export const API_ENDPOINT = 'https://backoffice.coescocolombia.com/';
export const TERMS_TEXT = `La información personal suministrada a COESCO Colombia SAS, con NIT 901.300. 741-5 y domiciliada en Bogotá D.C., será tratada con el fin de cumplir los diferentes compromisos y obligaciones derivados de la relación comercial y para fines administrativos tales como administración de sistemas de información, contabilidad, facturación, etc. Con su aceptación manifiesta que:\n\n1. Autoriza de manera libre, previa, expresa e informada a COESCO Colombia S.A.S. y/o a la persona natural o jurídica a quién ésta eventualmente designe, para que trate los datos personales que le ha suministrado y/o que le suministrará.\n\n2. Conoce sus derechos, previstos en la constitución y en la ley 1581 de 2012, y entiende que para mayor información puede acceder a la política de tratamiento de la información, disponible en la oficina del Gerente de administración y control, ubicada en la Calle 90 No.19C-32 - Bogotá, Colombia, a quien puede contactar en el Teléfono: 571-6283323.`;
export const APP_COLORS =  {
    primary:"#ff6900",
    secondary:"#a41b00",
    light:"#ffffff",
    shade:"#cccccc"
};
