import { object, string, number, mixed } from "yup";

/*----------------------------------------
ESQUEMAS DE VALIDACION FORMULARIO PRESONAS
-----------------------------------------*/

export const validationSchema = object().shape({
    peopleData: object().shape({
    document: number()
      .integer("El documento debe ser un número")
      .test(
        'NumberDocument',
        "No es posible registrar el consumidor final",
        (value) => String(value).match(/^2222/) ? false : true,
      )
      .test(
        'DocumentSize', 
        'El documento debe tener mínimo 4 digitos', 
        //validar longitud de dcto
        val => !val || (val.toString().length >= 4)
      )
      .typeError("El documento debe ser un número")
      .required("El documento es requerido"),
    name: string()
        .typeError("El primer nombre debe ser una cadena de carácteres"),
        //.required("El nombre es requerido"),
    optional_name: string()
      .typeError("El segundo nombre debe ser una cadena de caracteres"),
    last_name: string()
        .typeError("Los apellidos debe ser una cadena de carácteres"),
        //.required("Los apellidos son requeridos"),
    optional_last_name: string(),
        //.typeError("El segundo apellido de ser una cadena de caracteres"),
    business_name: string()
        .typeError("La razon social debe ser una cadena de caracteres"),
        //.required("La razón social es requerida"),
    rut_file:mixed()
      .test(
        "fileSize",
        "File is too large",
        value => !value || (value && value.size <= 200000)
      )
      .test(
        "fileFormat",
        "Unsupported Format",
        value => !value || (value => value && "application/pdf".includes(value.type))
      ),
    }),
    contactData: object().shape({
    /*address: string().typeError(
        "La dirección debe ser una cadena de caracteres")
        .required("La dirección es requerida"),*/
    postal_code:string()
    .typeError("El código postal debe ser un número")
    .test(
      'PostalCodeSizetSize', 
      'El código postal debe tener 6 digitos', 
      //validar longitud de codigo postal
      val => !val || (val.toString().length == 6)
    )
    .typeError("El código postal debe ser un número"),
    //.required("El código postal es requerido"),
    email_delivery: string()
        .typeError("E-mail inválido")
        .email("E-mail inválido")
        .required("El E-mail es requerido"),
    phone: number()
        .integer("El teléfono debe ser un número")
        .test(
          'PhoneSize', 
          'El teléfono debe tener mínimo 7 digitos', 
          //validar longitud de telefono
          val => !val || (val.toString().length >= 7)
        )
        .typeError("El teléfono debe ser un número")
        //.required("El teléfono es requerido"),
    }),
});


/*--------------------------------------------
ESQUEMAS DE VALIDACION ENVIO KEYEDIT AL E-MAIL
---------------------------------------------*/

export const validationDocumentSchema = object().shape({
    peopleData: object().shape({
      document: string()
        .typeError("El documento debe ser una cadena de carácteres")
        .required("El documento es requerido"),
    })
});

/*--------------------------------------------------
ESQUEMAS DE VALIDACION DOCUMENTO Y KEY DE LA PERSONA
---------------------------------------------------*/

export const validationDocumentAndKeySchema = object().shape({
    peopleData: object().shape({
      document: string()
        .typeError("El documento debe ser una cadena de carácteres")
        .required("El documento es requerido"),

      edition_key: string()
        .typeError("La llave de edición debe ser una cadena de carácteres")
        .required("La llave de edición es requerida"),
    }),
  });


/*--------------------------------------------------
ESQUEMAS DE VALIDACION DE KEY DE LA PERSONA
---------------------------------------------------*/

export  const validationKeySchema = object().shape({
    peopleData: object().shape({
      edition_key: string()
        .typeError("La llave de edición debe ser una cadena de carácteres")
        .required("La llave de edición es requerida"),
    }),

  });

/*--------------------------------------------------
ESQUEMAS DE VALIDACION DE VENTAS PARA FACTURAR

---------------------------------------------------*/
export  const validationSaleSchema = object().shape({
    
    billingData: object().shape({
      pcc: string()
      .typeError("El PCC debe ser una cadena de carácteres")
      .required("El PCC es requerido"),

      receipt: string()
      .typeError("El identificador de venta debe ser una cadena de carácteres")
      .required("El identificador de venta es requerido"),

      sale_code: string()
      .typeError("El código debe ser una cadena de carácteres")
      .required("El código es requerido"),
    })
  });